import React, { FC, useEffect, useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { TNode, ViewType } from '../../types/index.types';
import { useLineageFunctions } from '../../hooks/useLineageFunctions';
import { useEditIntelligence } from '../../requests/services/data-lineage/hooks';

const IntelligenceInfoPane: FC<{
	selectedNodes: TNode[];
	view: ViewType;
	resetAndGetLineageData: () => Promise<void>;
}> = ({ selectedNodes, view, resetAndGetLineageData }) => {
	const [nodes, setNodes] = useState<TNode[]>([]);
	const { mutate: editIntelligence, isPending } = useEditIntelligence(
		resetAndGetLineageData,
	);
	const [lastSelectedNode, setLastSelectedNode] = useState(
		nodes[nodes.length - 1],
	);
	const { getOldAncestry } = useLineageFunctions();

	const [nodeAncestry, setNodeAncestry] = useState({
		old_ancestry: getOldAncestry(selectedNodes),
		new_ancestry: getOldAncestry(selectedNodes),
	});

	const numberValues = ['weighting'];

	const handleChange = (e: any, last?: boolean) => {
		const { name, value } = e.target;
		setNodes(prevNodes =>
			prevNodes.map((node, index) => {
				if (last && index + 1 === prevNodes.length) {
					return {
						...node,
						[name]: numberValues.includes(name)
							? parseFloat(value === '' ? 0 : value)
							: value,
					};
				}
				if (node.label === name) {
					return { ...node, value };
				}
				return node;
			}),
		);
	};

	useEffect(() => {
		setNodeAncestry({
			...nodeAncestry,
			new_ancestry: getOldAncestry(nodes),
		});
	}, [nodes]);

	useEffect(() => {
		const _selectedNodes = selectedNodes;
		setNodes(_selectedNodes);
	}, [selectedNodes]);

	useEffect(() => {
		setLastSelectedNode(nodes[nodes.length - 1]);
	}, [nodes]);

	useEffect(() => {
		setNodeAncestry({
			old_ancestry: getOldAncestry(selectedNodes),
			new_ancestry: getOldAncestry(selectedNodes),
		});
	}, [selectedNodes]);

	const cancelChanges = () => {
		setNodes(selectedNodes);
	};

	const saveChanges = () => {
		editIntelligence(nodeAncestry);
	};

	return (
		<div className='min-w-[300px] w-[300px] duration-300 flex flex-col justify-between bg-white h-[calc(100vh_-_145px)] text-mlk-blue pt-5 sticky top-[164px] right-0 overflow-y-auto'>
			<div className='pb-10'>
				<div className='text-black flex space-x-2 px-5'>
					<div className='flex'>
						<InformationCircleIcon className='w-6' />
					</div>
					<h4 className='font-semibold text-xl'>
						{nodes[nodes.length - 1]?.value ?? ''}
					</h4>
				</div>
				<div className='mt-8 space-y-6 px-5'>
					{nodes.map((node, index) => (
						<div key={index} className='space-y-1'>
							<p className='text-stone-500 text-sm'>
								Level {node.level}:{' '}
								{node.label === 'Vioscore'
									? 'VioScore™/Non-VioScore™'
									: node.label ?? ''}
							</p>
							<select
								defaultValue={node.value ?? ''}
								value={node.value ?? ''}
								name={node.label}
								onChange={handleChange}
								id={node.label}
								disabled={node.level === 1}
								className='cursor-pointer text-black border-stone-200 disabled:bg-stone-200 rounded-md w-full'
							>
								{node.options &&
									node.options.map((option, index) => (
										<option key={index} value={option}>
											{option}
										</option>
									))}
							</select>
						</div>
					))}
					{lastSelectedNode?.polarity && (
						<div className='space-y-1'>
							<p className='text-stone-500 text-sm'>Polarity</p>
							<select
								name='polarity'
								id='polarity'
								onChange={e => handleChange(e, true)}
								value={lastSelectedNode?.polarity ?? 'Neutral'}
								className='cursor-pointer text-black border-stone-200 disabled:bg-stone-200 rounded-md w-full'
							>
								<option value='Neutral'>Neutral</option>
								<option value='Positive'>Positive</option>
								<option value='Negative'>Negative</option>
							</select>
						</div>
					)}
					{lastSelectedNode?.table && (
						<div className='space-y-1'>
							<p className='text-stone-500 text-sm'>Table</p>
							<input
								value={lastSelectedNode?.table ?? ''}
								onChange={handleChange}
								disabled
								className='text-black border-stone-200 disabled:bg-stone-200 rounded-md w-full'
							/>
						</div>
					)}

					{(lastSelectedNode?.weighting === 0 ||
						lastSelectedNode?.weighting) && (
						<div className='space-y-1'>
							<p className='text-stone-500 text-sm'>Weighting</p>
							<input
								value={lastSelectedNode?.weighting}
								name='weighting'
								step='0.01'
								min={0}
								onChange={e => handleChange(e, true)}
								type='number'
								className='text-black border-stone-200 text-right disabled:bg-stone-200 rounded-md w-full'
							/>
						</div>
					)}
					{lastSelectedNode?.vioscore_max && (
						<div className='space-y-1'>
							<p className='text-stone-500 text-sm'>
								VioScore™ Points
							</p>
							<input
								value={
									`${lastSelectedNode?.vioscore}/${lastSelectedNode?.vioscore_max}` ??
									''
								}
								disabled
								className='text-black text-right border-stone-200 disabled:bg-stone-200 rounded-md w-full'
							/>
						</div>
					)}
				</div>
			</div>
			<div className='flex justify-end w-full space-x-5 pt-3 pb-5 sticky bg-white bottom-0 left-0 z-10 border-t px-5'>
				<button
					onClick={cancelChanges}
					className='cursor-pointer border py-2 px-4 rounded-md border-mlk-light-blue text-mlk-light-blue uppercase font-semibold text-sm'
				>
					Cancel
				</button>
				<button
					disabled={isPending}
					onClick={saveChanges}
					className='cursor-pointer border py-2 px-4 rounded-md border-mlk-light-blue bg-mlk-light-blue text-white uppercase font-semibold text-sm disabled:cursor-not-allowed disabled:bg-slate-300 disabled:text-mlk-dark-blue disabled:border-slate-300'
				>
					{isPending ? 'Saving...' : 'Save'}
				</button>
			</div>
		</div>
	);
};

export default IntelligenceInfoPane;

import { Calendar, Database, Text } from 'lucide-react';
import { ReactNode } from 'react';

export const supabase_schemas: {
	icon: ReactNode;
	label: string;
	//value: string;
	//text: string;
}[] = [
	{
		icon: <Database className='w-5 h-5' />,
		label: 'public',
		//	value: 'public',
	},
	{
		icon: <Database className='w-5 h-5' />,
		label: '_analytics',
		//	value: 'public',
	},
	{
		icon: <Database className='w-5 h-5' />,
		label: 'auth',
		//value: 'public',
	},
	{
		icon: <Database className='w-5 h-5' />,
		label: 'extensions',
		//value: 'public',
	},
	{
		icon: <Database className='w-5 h-5' />,
		label: 'graphql',
		//value: 'public',
	},
	{
		icon: <Database className='w-5 h-5' />,
		label: 'supabase_functions',
		//value: 'public',
	},
	// {
	// 	icon: <Text />,
	// 	label: 'varchar',
	// 	text: 'Variable-length character string',
	// 	value: 'character varying',
	// },
	// {
	// 	icon: <Calendar />,
	// 	label: 'date',
	// 	text: 'Calendar date (year, month, day)',
	// 	value: 'date',
	// },
	// {
	// 	icon: <Text />,
	// 	label: 'bool',
	// 	text: 'Logical boolean (true/false)',
	// 	value: 'boolean',
	// },
];

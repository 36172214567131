import React, { useState } from 'react';
import {
	Sheet,
	SheetTrigger,
	SheetTitle,
	SheetDescription,
	SheetContent,
	SheetHeader,
	SheetFooter,
} from '../ui/sheet';
import { Button } from '../ui/button';
import {
	Database,
	ExternalLink,
	HelpCircle,
	Link2,
	Maximize2,
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { Input } from '../form/textField';
import { Label } from '../ui/label';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '../ui/select';
import { supabase_schemas } from '../../utils/supabase-schemas';
import {
	getRefTableHeaders,
	getTableList,
} from '../../requests/services/data-import/hook';
import useDataImportStore from '../../stores/dataImportStore';
import clsx from 'clsx';

const FKeyButton = ({
	idx,
	title,
	fkey,
	isPrimaryKey,
}: {
	idx: number;
	title: string;
	fkey: string;
	isPrimaryKey: string;
}) => {
	const [showText, setShowText] = useState(false);
	const [showAction, setShowAction] = useState(false);
	const { data, error } = getTableList();
	const [open, setOpen] = useState(false);
	const [ref_table, setRefTable] = useState('');
	const [col, setCol] = useState('');
	const [action, setAction] = useState('No action');
	const setColFkey = useDataImportStore(
		state => state.reset_table_data_value,
	);
	const table_name = useDataImportStore(state => state.table_name);
	const setFkey = useDataImportStore(state => state.setFkey_constraint);
	const { data: table_headers, refetch } = getRefTableHeaders(ref_table);

	const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const fkey_string = `alter table ${table_name} add constraint fkey_${ref_table} foreign key ("${title}") references ${ref_table} (${col}) on delete ${action}`;
		//console.log(fkey_string);
		setColFkey(idx, 'fkey', 'true');
		setFkey(fkey_string);
		setOpen(false);
	};
	return (
		<Sheet open={open} onOpenChange={setOpen}>
			<SheetTrigger asChild>
				<Button
					disabled={isPrimaryKey !== 'null'}
					onClick={() => setOpen(true)}
					className={clsx(
						'w-9 h-9 flex px-2 items-center text-mlk-light-blue justify-center rounded-[4px] bg-white/10 hover:bg-white/20',
						fkey !== 'null' && 'bg-mlk-light-blue text-white',
					)}
				>
					<Link2 />
				</Button>
			</SheetTrigger>
			<SheetContent
				asChild
				className='border-white/10 bg-mlk-blue text-white px-0 flex w-full flex-col justify-between'
			>
				<form onSubmit={e => handleSave(e)}>
					<div>
						<SheetHeader className='border-b border-b-white/10 px-5 pb-5'>
							<SheetTitle className='text-base'>
								Edit foreign key relation for{' '}
								<span className='ml-1 border border-gray-400 p-[3px] bg-gray-400 text-black text-sm'>
									{title}
								</span>
							</SheetTitle>
						</SheetHeader>
						<div className='mt-8 mx-5 flex flex-col space-y-5'>
							<div className='border border-white/10 w-full px-5 py-2 flex flex-col space-y-3 bg-white/10 rounded-md'>
								<div className='flex gap-2 items-center font-grotesk'>
									<HelpCircle className='w-5 h-5 text-mlk-light-blue' />
									<p className='flex-1 text-sm'>
										What are foreign keys?
									</p>
									<Maximize2
										className='h-4 w-4 text-mlk-light-blue hover:cursor-pointer'
										onClick={() => setShowText(!showText)}
									/>
								</div>
								{showText && (
									<div>
										<p className='text-xs'>
											Foreign keys help maintain
											referential integrity of your data
											by ensuring that no one can insert
											rows into the table that do not have
											a matching entry to another table.
										</p>
										<Button
											type='button'
											asChild
											variant='outline'
											size='sm'
											className='border-white/10 justify-around mt-3 gap-2 hover:border-gray-200'
										>
											<Link to='https://www.postgresql.org/docs/current/tutorial-fk.html'>
												<ExternalLink className='w-4 h-4 text-mlk-light-blue' />
												<p className='text-xs'>
													Postgres Foreign Key
													Documentation
												</p>
											</Link>
										</Button>
									</div>
								)}
							</div>
							<div>
								<Label className='text-right text-[#E0E0E0] font-roboto font-semibold text-[12px] leading-7'>
									Select a schema
								</Label>
								<Select
									//	onValueChange={e => setValue(idx, e)}
									defaultValue='public'
								>
									<SelectTrigger className='w-full text-[12px] text-[#E0E0E0] font-semibold font-roboto leading-7 h-9 px-2 focus:border-white/10 focus:ring-white/10 bg-white/10  border border-white/10'>
										<SelectValue placeholder='public' />
									</SelectTrigger>
									<SelectContent
										defaultValue='public'
										className='bg-white text-black'
										onCloseAutoFocus={e =>
											e.preventDefault()
										}
									>
										{supabase_schemas.map((el, idx) => (
											<SelectItem
												value={el.label}
												key={idx}
											>
												<div className='flex items-center w-full gap-2'>
													{el.icon}
													{el.label}
												</div>
											</SelectItem>
										))}
									</SelectContent>
								</Select>
							</div>
							<div>
								<Label className='text-right text-[#E0E0E0] font-roboto font-semibold text-[12px] leading-7'>
									Select a table to reference to
								</Label>
								<Select
									required
									onValueChange={e => {
										refetch();
										setRefTable(e);
									}}
									//defaultValue={level.value}
								>
									<SelectTrigger className='w-full text-[12px] text-[#E0E0E0] font-semibold font-roboto leading-7 h-9 px-2 focus:border-white/10 focus:ring-white/10 bg-white/10  border border-white/10'>
										<SelectValue placeholder='----' />
									</SelectTrigger>
									<SelectContent
										className='bg-white text-black'
										onCloseAutoFocus={e =>
											e.preventDefault()
										}
									>
										{data?.map(
											(
												el: { name: string },
												idx: number,
											) => (
												<SelectItem
													value={el.name}
													key={idx}
												>
													{el.name}
												</SelectItem>
											),
										)}
									</SelectContent>
								</Select>
							</div>
							<div>
								<Label className='text-right text-[#E0E0E0] font-roboto font-semibold text-[12px] leading-7'>
									{`Select a column from ${
										ref_table || '---'
									} to reference to`}
								</Label>
								<Select
									required
									onValueChange={e => {
										refetch();
										setCol(e);
									}}
									//defaultValue={level.value}
								>
									<SelectTrigger className='w-full text-[12px] text-[#E0E0E0] font-semibold font-roboto leading-7 h-9 px-2 focus:border-white/10 focus:ring-white/10 bg-white/10  border border-white/10'>
										<SelectValue placeholder='----' />
									</SelectTrigger>
									<SelectContent
										className='bg-white text-black'
										onCloseAutoFocus={e =>
											e.preventDefault()
										}
									>
										{table_headers?.map(
											(
												el: { name: string },
												idx: number,
											) => (
												<SelectItem
													value={el.name}
													key={idx}
												>
													{el.name}
												</SelectItem>
											),
										)}
									</SelectContent>
								</Select>
							</div>
							<div className='space-y-3'></div>
							<div className=' gap-4 border border-white/10 w-full px-5  py-2 flex flex-col bg-white/10 rounded-md'>
								<div className='flex gap-2 items-center font-grotesk'>
									<HelpCircle className='w-5 h-5 text-mlk-light-blue' />
									<p className='flex-1 text-sm'>
										Which action is most appropriate?
									</p>
									<Maximize2
										className='h-4 w-4 text-mlk-light-blue hover:cursor-pointer'
										onClick={() =>
											setShowAction(!showAction)
										}
									/>
								</div>
								{showAction && (
									<div>
										<p className='text-xs mb-1'>
											The choice of the action depends on
											what kinds of objects the related
											tables represent:
										</p>
										<ul className='list-disc text-xs space-y-2'>
											<li>
												<span className='border border-white/10 px-1 py-[2px] font-semibold text-gray-500 mr-1'>
													Cascade:
												</span>
												if the referencing table
												represents something that is a
												component of what is represented
												by the referenced table and
												cannot exist independently
											</li>
											<li>
												<span className='border border-white/10 px-1 py-[2px] font-semibold text-gray-500 mr-1'>
													Restrict
												</span>
												or{' '}
												<span className='border border-white/10 px-1 py-[2px] font-semibold text-gray-500 mr-1'>
													No action :
												</span>
												if the two tables represent
												independent objects
											</li>
											<li>
												<span className='border border-white/10 px-1 py-[2px] font-semibold text-gray-500 mr-1'>
													Set Null
												</span>
												or{' '}
												<span className='border border-white/10 px-1 py-[2px] font-semibold text-gray-500 mr-1'>
													Set Default :
												</span>
												if a foreign-key relationship
												represents optional information
											</li>
										</ul>
										<p className='text-xs mt-2'>
											Typically, restricting and cascading
											deletes are the most common options,
											but the default behavior is no
											action
										</p>
										{/* <Button
										asChild
										variant='outline'
										size='sm'
										className='border-white/10 justify-around mt-3 gap-2 hover:border-gray-200'
									>
										<Link to='https://www.postgresql.org/docs/current/tutorial-fk.html'>
											<ExternalLink className='w-4 h-4 text-mlk-light-blue' />
											<p className='text-xs'>
												Postgres Foreign Key
												Documentation
											</p>
										</Link>
									</Button> */}
									</div>
								)}
							</div>
							<div>
								<Label className='text-right text-[#E0E0E0] font-roboto font-semibold text-[12px] leading-7'>
									Action if referenced row is removed
								</Label>
								<Select
									onValueChange={e => setAction(e)}
									defaultValue='No action'
								>
									<SelectTrigger className='w-full text-[12px] text-[#E0E0E0] font-semibold font-roboto leading-7 h-9 px-2 focus:border-white/10 focus:ring-white/10 bg-white/10  border border-white/10'>
										<SelectValue placeholder='No action' />
									</SelectTrigger>
									<SelectContent
										className='bg-white text-black'
										onCloseAutoFocus={e =>
											e.preventDefault()
										}
									>
										<SelectItem value='No action'>
											No action
										</SelectItem>
										<SelectItem value='Cascade'>
											Cascade
										</SelectItem>
										<SelectItem value='Restrict'>
											Restrict
										</SelectItem>
										<SelectItem value='Set default'>
											Set default
										</SelectItem>
										<SelectItem value='Set NULL'>
											Set NULL
										</SelectItem>
									</SelectContent>
								</Select>
							</div>
						</div>
					</div>

					<SheetFooter className='border-t border-t-white/10 px-5 pt-5'>
						{/* <PreviewModal /> */}
						<Button
							type='button'
							onClick={() => {
								setColFkey(idx, 'fkey', 'null');
								setFkey('null');
								setRefTable('');
								setCol('');

								setOpen(false);
							}}
							variant='outline'
							className='disabled:bg-mlk-light-blue/95 disabled:text-white border-mlk-light-blue w-[100px] h-9 text-mlk-light-blue hover:bg-mlk-light-blue hover:text-white  rounded-[4px] font-grotesk text-[12px] font-bold leading-[14.81px]'
						>
							CANCEL
						</Button>
						<Button
							type='submit'
							variant='outline'
							className='disabled:bg-mlk-light-blue/95 disabled:text-white border-mlk-light-blue w-[100px] h-9 text-mlk-light-blue hover:bg-mlk-light-blue hover:text-white  rounded-[4px] font-grotesk text-[12px] font-bold leading-[14.81px]'
						>
							{/* {isLoading ? (
								<>
									<Loader2 className='mr-2 h-4 w-4 animate-spin' />
									Saving..
								</>
							) : ( */}
							SAVE
							{/* )} */}
						</Button>
					</SheetFooter>
				</form>
			</SheetContent>
		</Sheet>
	);
};

export default FKeyButton;

import { Tlevel } from '../stores/hierarchyDefinitionStore';

export const IncludesNulls = (levels: Tlevel[]): boolean => {
	let res = false;
	let prev_null_value: string | null = null;
	levels.forEach((el, idx) => {
		if (el.value != 'NULL' && prev_null_value == 'NULL') {
			res = true;
		}
		if (el.value === 'NULL') {
			prev_null_value = 'NULL';
		}
	});
	return res;
};

import { create } from 'zustand';
import { PasswordResetSchema } from '../utils/schema';
import { persist, createJSONStorage } from 'zustand/middleware';
import { AuthChangeEvent } from '@supabase/supabase-js';

type responseType =
	| 'health_vioscore'
	| 'environment_vioscore'
	| 'world_vioscore'
	| 'total_vioscore';
interface IPostCode {
	postcode: string;
	donought_data: { name: string; value: number; fill: string; bg: string }[];
	updateCode: (code: string) => void;
	creator_id: string;
	vioscore: number | null;
	updateVioscore: (val: number) => void;
	updateId: (val: string) => void;
	update_donought_data: (res: Record<string, number>) => void;
}

const data = [
	{
		name: 'health_vioscore',
		value: 500,
		fill: '#FF2858',
		bg: 'bg-eurologySkyBlue',
	}, //
	//{ name: 'Credit', value: 100, fill: '#FFBD02', bg: 'bg-eurologySkyBlue' }, //
	{
		name: 'environment_vioscore',
		value: 100,
		fill: '#84cc16',
		bg: 'bg-eurologySkyBlue',
	}, //"
	{
		name: 'world_vioscore',
		value: 40,
		fill: '#00B8F6',
		bg: 'bg-eurologySkyBlue',
	},
	{
		name: 'total_vioscore',
		value: 640,
		fill: '#242c47',
		bg: 'bg-eurologySkyBlue',
	},

	// "#00B8F6",
];
const useInhabitantStore = create<IPostCode>()(
	persist(
		(set, get) => ({
			donought_data: data,
			postcode: '',
			creator_id: '',
			vioscore: 0,
			updateVioscore: val => set({ vioscore: val }),
			updateId: val => set({ creator_id: val }),
			updateCode: val =>
				set({
					postcode: val,
				}),
			update_donought_data(res) {
				const data_arr = get().donought_data;

				for (const key in res) {
					if (Object.prototype.hasOwnProperty.call(res, key)) {
						const element = res[key];
						const elm = data_arr.find(el => el.name === key);
						if (elm) {
							if (elm.name == 'total_vioscore') {
								const unscored =
									1000 - Math.round(res.total_vioscore);
								const unscored_to_degrees = unscored * 0.36;
								elm.value = Math.round(unscored_to_degrees);
							} else {
								const val_to_degrees = element * 0.36;
								elm.value = Math.round(val_to_degrees);
							}
						}
					}
				}

				set({ donought_data: data_arr });
			},
		}),
		{
			name: 'lastest_lookup',
			storage: createJSONStorage(() => localStorage),
		},
	),
);

export default useInhabitantStore;

import { isValid } from 'date-fns';

export const isDataTypeof = (el: unknown): string => {
	let data_type = 'text';

	if (typeof el == 'number' && !Number.isInteger(el)) {
		data_type = 'double precision';
	}

	if (typeof el === 'number' && Number.isInteger(el)) {
		data_type = 'integer';
	}

	if (typeof el === 'string' && isValid(new Date(el as string))) {
		data_type = 'date';
	}

	return data_type;
};

import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import DatabaseIcon from '../../assets/database.svg';
import FileIcon from '../../assets/file.svg';
import CloudIcon from '../../assets/cloud.svg';
import clsx from 'clsx';
import { useUtilityFunctions } from '../../hooks/utilityFunctions';
import { ViewType } from '../../types/index.types';
import { useLineageFunctions } from '../../hooks/useLineageFunctions';
import {
	blockedNonVioScoreNodes,
	blockedPermissionsNodes,
} from '../../utils/staticData';

// interface TreeNode {
//     name: string;
//     children?: TreeNode[];
// }

interface LinearGraphProps {
	data: any;
	svgContainerRef: React.RefObject<HTMLDivElement>;
	clickSelectedNode?: (node: any) => void;
	scale?: number;
	view?: ViewType;
}

const icons = [
	{
		name: 'database',
		icon: DatabaseIcon,
	},
	{
		name: 'file',
		icon: FileIcon,
	},
	{
		name: 'cloud',
		icon: CloudIcon,
	},
];

const LinearGraph: React.FC<LinearGraphProps> = ({
	data,
	clickSelectedNode,
	scale,
	view,
	svgContainerRef,
}) => {
	const { setContainerFlex } = useLineageFunctions();
	const svgRef = useRef<SVGSVGElement | null>(null);
	const [blockedNodes, setBlockedNodes] = useState<any>([]);
	const [nonVioScoreNodes, setNonVioScoreNodes] = useState<any>([]);
	const { splitCapitalizedText } = useUtilityFunctions();
	const { getLineageName, getLineageColors, getBlockedNodes } =
		useLineageFunctions();

	//console.log(data);

	useEffect(() => {
		setBlockedNodes([]);
		setTimeout(() => {
			if (view === 'Data') {
				setBlockedNodes(getBlockedNodes(data, blockedPermissionsNodes));
			}
		}, 100);
		//eslint-disable-next-line
	}, [data, view]);

	useEffect(() => {
		setNonVioScoreNodes(getBlockedNodes(data, blockedNonVioScoreNodes));
		//eslint-disable-next-line
	}, [data, blockedNonVioScoreNodes]);

	useEffect(() => {
		if (!data || !svgRef.current) return;

		const margin = { top: 0, right: 200, bottom: 30, left: 50 };
		const colors = {
			background: '#F0F0F0',
			mlkBlue: '#586FC5',
			mlkLightBlue: '#A7B1DB',
			mlkDarkBlue: '#131828',
			mlkBlueBorder: '#02B9F7',
		};
		// Specify the charts’ dimensions. The height is variable, depending on the layout.
		const widthDifference =
			Object.keys(data)?.length + 2000 - margin.left - margin.right;

		const root: any = d3.hierarchy(data);
		const dx = 100;
		const dy =
			(widthDifference - margin.right - margin.left) / (1 + root.height);

		// Define the tree layout and the shape for links.
		const tree = d3.tree().nodeSize([dx, dy]);
		const diagonal = d3
			.linkHorizontal()
			.x((d: any) => d.y)
			.y((d: any) => d.x);

		// Create the SVG container, a layer for the links and a layer for the nodes.
		const svg = d3
			.select(svgRef.current)
			.attr('width', widthDifference)
			.attr('height', dx)
			.attr('viewBox', [-margin.left, -margin.top, widthDifference, dx])
			.attr('style', 'height: auto; font-size: 15px; user-select: none;');

		const gLink = svg
			.append('g')
			.attr('fill', 'none')
			.attr('stroke', '#555')
			.attr('stroke-opacity', 0.4)
			.attr('stroke-width', 1.5);

		const gNode = svg
			.append('g')
			.attr('cursor', 'pointer')
			.attr('pointer-events', 'all');

		const update = (event: any, source: any) => {
			const duration = event?.altKey ? 2500 : 250; // hold the alt key to slow down the transition
			const nodes = root.descendants().reverse();
			const links = root.links();

			// Compute the new tree layout.
			tree(root);

			let maxWidth = 0;

			// Calculate minX and maxX
			nodes.forEach((d: any) => {
				maxWidth = Math.max(maxWidth, d.y * 1.15);
			});

			const newWidth = maxWidth + margin.left + margin.right;

			svg.attr('width', newWidth);
			if (svgRef.current?.parentElement) {
				if (
					svgRef.current?.parentElement.scrollWidth >
					svgRef.current?.parentElement.clientWidth
				) {
					svgRef.current.style.marginLeft = '100px';
					svgRef.current.style.marginRight = '-250px';
				} else {
					svgRef.current.style.marginLeft = '50px';
					svgRef.current.style.marginRight = '0px';
				}
			}

			let left = root;
			let right = root;
			root.eachBefore((node: any) => {
				if (node.x < left.x) left = node;
				if (node.x > right.x) right = node;
			});

			const height = right.x - left.x + margin.top + margin.bottom;

			const transition = svg
				.transition()
				.duration(duration)
				.attr('height', height)
				.attr(
					'viewBox',
					`${-margin.left}, ${
						left.x - margin.top
					}, ${newWidth}, ${height}`,
				)
				.tween(
					'resize',
					// @ts-ignore
					window.ResizeObserver
						? null
						: () => () => svg.dispatch('toggle'),
				);

			// Update the nodes…
			const node = gNode.selectAll('g').data(nodes, (d: any) => d.id);

			// Enter any new nodes at the parent's previous position.
			const nodeEnter = node
				.enter()
				.append('g')
				.attr('transform', () => `translate(${source.y0},${source.x0})`)
				.attr('fill-opacity', 0)
				.attr('stroke-opacity', 0)
				.on('click', (event, d: any) => {
					if (clickSelectedNode) {
						clickSelectedNode({
							// name: splitCapitalizedText(getLineageName(d)),
							// vioscore: d.data.vioscore,
							index: d.data.index,
							// code: d.data.code,
							// labels: d.data.labels,
							blocked: blockedNodes.includes(d.data.index),
						});

						// give border to rect
						const allRect = document.querySelectorAll('rect');
						const rect = document.getElementById(getLineageName(d));
						// remove stroke from allRect
						allRect.forEach(rect => {
							rect.style.stroke = 'transparent';
						});
						if (rect) {
							rect.style.stroke = 'rgba(0,0,0,0.5)';
						}
					}
					if (!d.data.blockAccess) {
						d.children = d.children ? null : d._children;
						update(event, d);
					}
				});

			nodeEnter
				.append('rect')
				.attr('id', (d: any) => `${getLineageName(d)}-rect`)
				.attr(
					'transform',
					(d: any) =>
						// `translate(${
						// 	-(
						// 		splitCapitalizedText(getLineageName(d)).length *
						// 			9 +
						// 		16
						// 	) + 20
						// }, 0)`,
						'translate(-20, 0)',
				)
				.attr('rx', 6) // Rounded border
				.attr('ry', 6)
				.attr('height', 30) // Increase the height
				.attr('id', (d: any) => getLineageName(d))
				// .style('stroke', '#575757')
				.style('stroke-width', 2)
				// .style('fill', (d: any) => d.data.blockAccess ? '#F8634E' : d.depth > 2 ? colors.mlkLightBlue : colors.mlkBlue)
				.style('fill', (d: any) => {
					return d.data.properties?.colour ?? colors.mlkBlue;
				})
				.style('display', (d: any) =>
					splitCapitalizedText(getLineageName(d)) === 'icon'
						? 'none'
						: '',
				);

			// nodeEnter.append("circle")
			//     .attr('r', 5)
			//     .style('stroke', (d: any) => d._children ? getLineageColors(d, view, blockedNodes, nonVioScoreNodes).bg : "")
			//     .attr('cx', () => 10) // Adjust the position as needed
			//     .attr('cy', 15)
			//     .attr('transform', `translate(10, 0)`)
			//     .style('stroke-width', 1.5)
			//     .style('fill', (d: any) => d._children ? colors.background : 'none');

			// Append the SVG element using innerHTML
			nodeEnter
				.append('foreignObject')
				.attr(
					'transform',
					(d: any) =>
						// `translate(${
						// 	-(
						// 		splitCapitalizedText(getLineageName(d)).length *
						// 			9 +
						// 		16
						// 	) + 28
						// }, 6)`,
						'translate(-13, 5.5)',
				)
				.attr('width', 16) // Set the width of the foreignObject to the width of your SVG icon
				.attr('height', 16) // Set the height of the foreignObject to the height of your SVG icon
				.append('xhtml:div')
				.style('display', 'inline-block')
				.html((d: any) => {
					// Include the SVG code within the HTML using innerHTML
					return `<div class="intelligence-icon text-white">${d.data.properties?.icon}</div>`;
				});

			nodeEnter
				.append('text')
				.attr(
					'transform',
					(d: any) =>
						// `translate(${
						// 	-(
						// 		splitCapitalizedText(getLineageName(d)).length *
						// 			9 +
						// 		16
						// 	) + 20
						// }, 0)`,
						'translate(-20, 0)',
				)
				.attr('dy', '1em') // Center text vertically
				.attr('dx', '1.7em') // Center text vertically
				.attr('text-anchor', 'left') // Center text horizontally
				.attr('dominant-baseline', 'middle') // Center text vertically
				.text((d: any) =>
					splitCapitalizedText(getLineageName(d)) !== 'icon'
						? getLineageName(d)
						: '',
				)
				.each(function () {
					// Calculate the width of the text
					const textWidth = this.getBBox().width;
					// Set the width of the rect to the width of the text plus some padding
					// @ts-ignore
					d3.select(this.parentNode) // Select the parent node (g element)
						.select('rect') // Select the rect element within the parent node
						.attr('width', textWidth + 50); // Set width of the rect
				})
				.style('font-size', '16px') // Change the font size here
				.style(
					'fill',
					(d: any) =>
						getLineageColors(d, view ?? 'Data', blockedNodes).text,
				);

			nodeEnter
				.append('circle')
				.attr(
					'transform',
					(d: any) =>
						`translate(${
							-(
								splitCapitalizedText(getLineageName(d)).length *
									9 +
								16
							) + 20
						}, 0)`,
				)
				.attr('r', 30)
				.attr('cx', (d: any) => 10) // Adjust the position as needed
				.attr('cy', 15)
				.style('fill', (d: any) =>
					d.data.blockAccess
						? '#F8634E'
						: d.data.backgroundColor ?? colors.mlkBlue,
				)
				.style('display', (d: any) =>
					splitCapitalizedText(getLineageName(d)) !== 'icon'
						? 'none'
						: '',
				);

			nodeEnter
				.append('svg:image')
				.attr(
					'xlink:href',
					(d: any) =>
						icons.find(icon => icon.name === d.data.iconType)
							?.icon ?? '',
				)
				.attr('x', -35)
				.attr('y', 2)
				.attr('width', 25)
				.attr('height', 25)
				.style('display', (d: any) =>
					splitCapitalizedText(getLineageName(d)) !== 'icon'
						? 'none'
						: '',
				);

			// Transition nodes to their new position.
			// @ts-ignore
			node.merge(nodeEnter)
				//@ts-ignore
				.transition(transition)
				.attr('transform', (d: any) => `translate(${d.y},${d.x})`)
				.attr('fill-opacity', 1)
				.attr('stroke-opacity', 1);

			// Transition exiting nodes to the parent's new position.
			// @ts-ignore
			node.exit()
				//@ts-ignore
				.transition(transition)
				.remove()
				.attr('transform', () => `translate(${source.y},${source.x})`)
				.attr('fill-opacity', 0)
				.attr('stroke-opacity', 0);

			// Update the links…
			const link = gLink
				.selectAll('path')
				.data(links, (d: any) => d.target.id);

			// Enter any new links at the parent's previous position.
			const linkEnter = link
				.enter()
				.append('path')
				.attr('d', () => {
					const o = { x: source.x0, y: source.y0 };
					// @ts-ignore
					return diagonal({ source: o, target: o });
				})
				.style('stroke-dasharray', '3, 3') // Set the dashed pattern
				.attr('transform', `translate(0, 15)`);

			// Transition links to their new position.
			// @ts-ignore
			link.merge(linkEnter)
				//@ts-ignore
				.transition(transition)
				// @ts-ignore
				.attr('d', diagonal);

			// Transition exiting nodes to the parent's new position.
			// @ts-ignore
			link.exit()
				//@ts-ignore
				.transition(transition)
				.remove()
				.attr('d', () => {
					const o = { x: source.x, y: source.y };
					// @ts-ignore
					return diagonal({ source: o, target: o });
				});

			// Stash the old positions for transition.
			root.eachBefore((d: any) => {
				d.x0 = d.x;
				d.y0 = d.y;
			});
			setContainerFlex && setContainerFlex(svgContainerRef);
		};

		// Do the first update to the initial configuration of the tree — where a number of nodes
		// are open (arbitrarily selected as the root, plus nodes with 7 letters).
		root.x0 = dy / 2;
		root.y0 = 0;
		root.descendants().forEach((d: any, i: number) => {
			d.id = i;
			d._children = d.children;
			d.children = null;
		});

		update(null, root);
		//eslint-disable-next-line
	}, [data, blockedNodes]);

	useEffect(() => {
		if (svgRef.current && scale) {
			svgRef.current.style.transform = `scale(${scale}) translate(${
				(scale - 1) * 30
			}%, ${(scale - 1) * 30}%)`;
		}
	}, [scale]);

	return (
		<svg
			ref={svgRef}
			className={clsx(
				scale ? `scale-[${scale}]` : '',
				'duration-300 h-full overflow-visible ml-[250px]',
			)}
		></svg>
	);
};

export default LinearGraph;

import React, { useEffect } from 'react';
import {
	createDynamicTable,
	rollbackTableCreation,
} from '../../requests/services/data-import/hook';
import useLoadigStatusStore from '../../stores/loadingStatusStore';
import { CheckCircleIcon, RefreshCw, XCircle } from 'lucide-react';

const CreateTable = () => {
	const begin = useLoadigStatusStore(state => state.is_start_import);
	const { isLoading, data, isError, error } = createDynamicTable(begin);
	useEffect(() => {
		if (data) {
			useLoadigStatusStore.setState({ is_table_created: true });
		}

		return () => {
			useLoadigStatusStore.setState({ is_table_created: false });
		};
	}, [data]);

	return (
		<div className='flex gap-2 items-center'>
			{isLoading ? (
				<RefreshCw className='text-mlk-light-blue w-6 h-6 animate-spin' />
			) : data ? (
				<CheckCircleIcon className='text-[#27AE60] w-6 h-6' />
			) : isError ? (
				<XCircle className='text-red-600 w-6 h-6' />
			) : null}

			<p className='font-roboto text-[12px] font-bold leading-7 text-[#E0E0E0]'>
				{isLoading
					? 'Creating Table'
					: data
					  ? 'Created Table'
					  : isError
					    ? error.message
					    : null}
			</p>
		</div>
	);
};

export default CreateTable;

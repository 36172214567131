import React, { useState } from 'react';
import { Label } from '../ui/label';
import { Input } from '../form/textField';
import { cn } from '../../utils/cn';
import clsx from 'clsx';
import { Button } from '../ui/button';
import { Info, Loader2 } from 'lucide-react';
import useInhabitantStore from '../../stores/inhabitantStore';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { postCode, postCodeSchema } from '../../utils/schema';
import { searchPostCode } from '../../requests/services/rate_your_day/getVioscoreData';
import { useToast } from '../ui/use-toast';
import { useQueryClient } from '@tanstack/react-query';
import { IVioscoreLookup } from '../../types/index.types';
import parsePostcode from '../../utils/parsePostcode';

const HouseHoldLookupForm = () => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<postCode>({
		resolver: zodResolver(postCodeSchema),
	});
	const [loading, setLoading] = useState(false);
	const { toast } = useToast();
	const id = useInhabitantStore(state => state.creator_id);
	const queryClient = useQueryClient();
	const updatePostCode = useInhabitantStore(state => state.updateCode);
	const updateDonutData = useInhabitantStore(
		state => state.update_donought_data,
	);
	const updateLatestVioscore = useInhabitantStore(
		state => state.updateVioscore,
	);

	const onSubmit: SubmitHandler<postCode> = async data => {
		setLoading(true);
		const postcodeExists = await searchPostCode(
			data.postcode.replace(/ /g, '').toUpperCase(),
		); //as IVioscoreLookup[] | null;
		//console.log(postcodeExists);
		if (postcodeExists && postcodeExists.length !== 0) {
			updatePostCode(parsePostcode(data.postcode));
			updateLatestVioscore(postcodeExists[0].total_vioscore);
			updateDonutData(postcodeExists[0]);
			setLoading(false);
		} else {
			toast({
				title: (
					<div className='flex items-center w-full gap-1'>
						<Info />
						<span>Household Lookup Notice</span>
					</div>
				),
				variant: 'destructive',
				className: 'bg-red-400 border-red-400 py-3',
				description: 'No data found for the Postcode provided..!',
			});

			setLoading(false);
		}
	};
	return (
		<div className='bg-mlk-blue border rounded-md mb-[33px] border-gray-600  pt-[21px] pl-[21px] pb-[31px] w-full min-h-[165px]  flex flex-col'>
			<div className='flex flex-col gap-6'>
				<p className='font-roboto font-normal text-2xl leading-[28.13px] text-white'>
					Household lookup
				</p>
				<form
					className='flex flex-col gap-[11px]'
					onSubmit={handleSubmit(onSubmit)}
				>
					<Label
						htmlFor='postcode'
						className={clsx(
							errors.postcode?.message
								? 'text-red-600'
								: 'text-white',
						)}
					>
						Postcode + House number
					</Label>
					<div className='flex items-center gap-2 w-[392px]'>
						<div>
							<Input
								{...register('postcode')}
								placeholder='ie: 9999 AB 99'
								className={cn(
									'w-[221px]',
									errors.postcode
										? 'border border-red-600 focus:border-red-600 focus:ring-red-600'
										: 'border border-white/10 focus:border-white/10 focus:ring-white/10',
									'h-9 py-5 px-[11px] bg-white/10 text-[#E0E0E0] font-roboto text-sm font-normal leading-7',
								)}
							/>
						</div>

						<Button
							type='submit'
							className=' text-sm font-medium leading-[16.41px] text-center flex items-center justify-center rounded-[4px] bg-mlk-light-blue text-white disabled:bg-opacity-50 disabled:bg-mlk-light-blue'
						>
							{loading ? (
								<>
									<Loader2 className='mr-2 h-4 w-4 animate-spin' />
									Please Wait
								</>
							) : (
								'SUBMIT'
							)}
						</Button>
					</div>
				</form>
			</div>
			{errors.postcode && (
				<small className='text-red-500 my-2 block !h-4'>
					{errors.postcode.message as string}
				</small>
			)}
			{/* {error && (
				<small className='text-red-500 my-2 block !h-4'>{error}</small>
			)} */}
		</div>
	);
};

export default HouseHoldLookupForm;

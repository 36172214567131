import { CheckCircleIcon, RefreshCw, XCircle } from 'lucide-react';
import React, { useEffect } from 'react';
import { createDynamicTableColumns } from '../../requests/services/data-import/hook';
import useDataImportStore from '../../stores/dataImportStore';
import clsx from 'clsx';
import useLoadigStatusStore from '../../stores/loadingStatusStore';
import { cn } from '../../utils/cn';

const CreateColumns = () => {
	const prevDone = useLoadigStatusStore(state => state.is_table_created);
	const { isLoading, data, isError, error, isPending } =
		createDynamicTableColumns(prevDone);
	//get the column length heree
	const columns = useDataImportStore(state => state.headers);
	useEffect(() => {
		if (data) {
			useLoadigStatusStore.setState({ is_columns_created: true });
		}

		return () => {
			useLoadigStatusStore.setState({ is_columns_created: false });
		};
	}, [data]);

	return (
		<div className='flex gap-2 items-center font-roboto font-semibold text-[12px]'>
			<div className='w-6'>
				{isLoading ? (
					<RefreshCw className='text-mlk-light-blue w-6 h-6 animate-spin' />
				) : data ? (
					<CheckCircleIcon className='text-[#27AE60] w-6 h-6' />
				) : isError ? (
					<XCircle className='text-red-600 w-6 h-6' />
				) : null}
			</div>
			<p
				className={cn(
					'font-roboto text-[12px] font-bold leading-7 text-gray-600',
					isLoading && 'text-[#E0E0E0]',
					data && 'text-[#E0E0E0]',
					isError && 'text-[#E0E0E0]',
				)}
			>
				{isLoading
					? `Creating ${columns.length} Columns`
					: data
					  ? `Created ${columns.length} Columns`
					  : isError
					    ? error.message
					    : 'Create columns'}
			</p>
		</div>
	);
};

export default CreateColumns;

import React, { FC } from 'react';
import { ViewType } from '../../types/index.types';
import { RefreshSvg } from '../svgs';
import { clsx } from 'clsx';
import { useDataLineage } from '../../requests/services/data-lineage/data-lineage.service';
import { Upload } from 'lucide-react';
import { useNavigate, useNavigation } from 'react-router-dom';
import { Separator } from '../ui/separator';

const IntelligenceNav: FC<{
	selectView: (view: ViewType) => void;
	resetAndGetLineageData: () => Promise<void>;
}> = ({ selectView, resetAndGetLineageData }) => {
	const [loading, setLoading] = React.useState(false);
	const navigate = useNavigate();
	const { refreshTables } = useDataLineage();

	const refreshDataLineage = async () => {
		setLoading(true);
		await refreshTables();
		await resetAndGetLineageData();
		setLoading(false);
	};

	return (
		<nav className='w-full flex justify-between bg-white h-16 sticky top-0 left-0 z-10 border-b border-[#F0F0F0] text-mlk-blue items-center px-5 py-4'>
			<select
				onChange={e => selectView(e.target.value as ViewType)}
				name='views'
				id='view'
				className='rounded-md text-sm border-gray-200 disabled:text-gray-400 cursor-pointer focus:border-mlk-blue focus:ring-mlk-dark-blue'
			>
				<option value='Data'>Data</option>
				<option value='Attributes'>Overview</option>
			</select>

			<div className='flex items-center gap-4'>
				<Upload
					className='w-6 h-6 text-mlk-light-blue hover:cursor-pointer'
					onClick={() => navigate('/intelligence/data-import')}
				/>
				<Separator
					orientation='vertical'
					className='border-[#D9D9D9] h-[26px] border'
				/>
				<button
					className={clsx(
						loading ? 'animate-spin' : '',
						'cursor-pointer',
					)}
					onClick={refreshDataLineage}
				>
					<RefreshSvg />
				</button>
			</div>
		</nav>
	);
};

export default IntelligenceNav;

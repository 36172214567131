import React, { FC, useEffect, useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { TNode, ViewType } from '../../types/index.types';
import { XIcon } from 'lucide-react';

const AttributesInfoPane: FC<{
	selectedNodes: TNode[];
	view: ViewType;
	resetAndGetLineageData: () => Promise<void>;
}> = ({ selectedNodes, view, resetAndGetLineageData }) => {
	const [nodes, setNodes] = useState<TNode[]>([]);
	const [viewCodeButton, setViewCodeButton] = useState({
		visible: false,
		code: '',
	});
	const [codePane, setCodePane] = useState(false);

	const customStyle = {
		...coy,
		'pre[class*="language-"]': {
			...coy['pre[class*="language-"]'],
			background: 'white',
		},
		'code[class*="language-"]': {
			...coy['code[class*="language-"]'],
			background: 'white',
		},
	};

	const codeString = `${viewCodeButton.code}`;

	useEffect(() => {
		const _selectedNodes = selectedNodes;
		setNodes(_selectedNodes);
	}, [selectedNodes]);

	useEffect(() => {
		const lastSelectedNode = nodes[nodes.length - 1];
		setViewCodeButton({
			visible: lastSelectedNode?.label === 'Code',
			code: lastSelectedNode?.code ?? '',
		});
	}, [nodes]);

	const viewCode = () => {
		setCodePane(true);
	};

	return (
		<>
			<div className='min-w-[300px] w-[300px] duration-300 flex flex-col bg-white h-[calc(100vh_-_145px)] text-mlk-blue py-5 sticky top-[164px] right-0 overflow-y-auto'>
				<div className='pb-7'>
					<div className='text-black flex space-x-2 px-5'>
						<div className='flex'>
							<InformationCircleIcon className='w-6' />
						</div>
						<h4 className='font-semibold text-xl'>
							{nodes[nodes.length - 1]?.value ?? ''}
						</h4>
					</div>
					<div className='mt-8 space-y-6 px-5'>
						{nodes.map((node, index) => (
							<div key={index} className='space-y-1.5'>
								<p className='text-stone-500 text-sm'>
									Level {node.level}:{' '}
									{node.label === 'Vioscore'
										? 'VioScore™/Non-VioScore™'
										: node.label ?? ''}
								</p>
								<p className='font-semibold'>{node.value}</p>
							</div>
						))}
					</div>
				</div>
				{viewCodeButton.visible && (
					<button
						onClick={viewCode}
						className='cursor-pointer w-fit ml-5 border py-2 px-4 rounded-md border-mlk-light-blue text-mlk-light-blue uppercase font-semibold text-sm'
					>
						View Code
					</button>
				)}
			</div>
			{codePane && (
				<div className='min-w-[500px] border-l w-[500px] duration-300 flex flex-col bg-white h-[calc(100vh_-_145px)] text-mlk-blue pb-5 sticky top-[164px] right-0 overflow-y-auto'>
					<div className='w-full py-3 border-b px-5 flex justify-between space-x-4'>
						<div className='flex items-center space-x-2'>
							<svg
								width='20'
								height='12'
								viewBox='0 0 20 12'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M6 12L0 6L6 0L7.425 1.425L2.825 6.025L7.4 10.6L6 12ZM14 12L12.575 10.575L17.175 5.975L12.6 1.4L14 0L20 6L14 12Z'
									fill='black'
								/>
							</svg>
							<p className='font-semibold'>
								scripts/rate-your-day__recommendations.js
							</p>
						</div>
						<XIcon
							className='text-mlk-light-blue cursor-pointer'
							onClick={() => setCodePane(false)}
						/>
					</div>
					<SyntaxHighlighter
						language='javascript'
						showLineNumbers
						style={customStyle}
					>
						{codeString}
					</SyntaxHighlighter>
				</div>
			)}
		</>
	);
};

export default AttributesInfoPane;
